.node-container[data-v-9bb39d44] {
  width: 100%;
  height: 100%;
}
.node-container .des-icon[data-v-9bb39d44] {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
}
.diamond-top[data-v-9bb39d44] {
  width: 0;
  height: 0;
  border-left: var(--9bb39d44-widthBorderValue) solid transparent;
  border-right: var(--9bb39d44-widthBorderValue) solid transparent;
  border-bottom: var(--9bb39d44-heightBorderValue) solid #187dff;
  position: relative;
}
.diamond-top[data-v-9bb39d44]:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  top: var(--9bb39d44-topValue);
  border-left: var(--9bb39d44-offsetWidthBorderValue) solid transparent;
  border-right: var(--9bb39d44-offsetWidthBorderValue) solid transparent;
  border-bottom: var(--9bb39d44-offsetHeightBorderValue) solid #fff;
}
.diamond-bottom[data-v-9bb39d44] {
  width: 0;
  height: 0;
  border-left: var(--9bb39d44-widthBorderValue) solid transparent;
  border-right: var(--9bb39d44-widthBorderValue) solid transparent;
  border-top: var(--9bb39d44-heightBorderValue) solid #187dff;
  position: relative;
}
.diamond-bottom[data-v-9bb39d44]::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  top: var(--9bb39d44--borderHeight\+\'px\');
  border-left: var(--9bb39d44-offsetWidthBorderValue) solid transparent;
  border-right: var(--9bb39d44-offsetWidthBorderValue) solid transparent;
  border-top: var(--9bb39d44-offsetHeightBorderValue) solid #fff;
}
