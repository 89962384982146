.icon[data-v-d1606b6f] {
  position: absolute;
}
.node-container[data-v-d1606b6f] {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
}
