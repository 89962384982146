.instruction-form[data-v-a687cea0] {
  display: flex;
  flex-wrap: wrap;
}
.instruction-form[data-v-a687cea0] label {
  margin-bottom: 0;
}
.instruction-form[data-v-a687cea0] .el-form-item {
  flex: 1;
  min-width: 150px;
}
