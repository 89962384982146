.bg[data-v-c0a6c663] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1019;
  background: #00000066;
}
.detail[data-v-c0a6c663] {
  overflow: auto;
  order: 3;
}
.detail.model[data-v-c0a6c663] {
  position: fixed;
  top: 0px;
  left: 40%;
  right: 0px;
  bottom: 0px;
  background: #FFF;
  border: 10px solid #FFF;
  border-width: 10px 0;
  border-radius: 0px;
  z-index: 1020;
}
