.node-container[data-v-c276aa77] {
  position: relative;
  display: flex;
  border: 2px solid #187dff;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.node-container-inner[data-v-c276aa77] {
  display: flex;
  border-left: 2px solid #187dff;
  border-right: 2px solid #187dff;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  height: 100%;
}
