.bg[data-v-bbf47719] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;
  background: #00000066;
}
.detail[data-v-bbf47719] {
  overflow: auto;
  order: 3;
}
.detail.model[data-v-bbf47719] {
  position: fixed;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  z-index: 1020;
  background: #FFF;
  border: 10px solid #FFF;
  border-width: 10px 0;
  border-radius: 10px;
}
