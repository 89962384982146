.header-container[data-v-0a239fb3] {
  display: flex;
}
.title[data-v-0a239fb3] {
  margin-bottom: 0;
  margin-right: 10px;
}
.dialog-container[data-v-0a239fb3] .el-dialog__body {
  padding-top: 0;
}
