.table[data-v-855d3594] .el-table__header-wrapper  .el-checkbox {
  display: none;
}
.table[data-v-855d3594] label {
  margin-bottom: 0;
}
.body[data-v-855d3594] {
  display: flex;
  flex-direction: column;
  height: 71vh;
}
.editor-container[data-v-855d3594] {
  flex: 1;
  overflow-y: auto;
}
.title[data-v-855d3594] {
  text-align: center;
}
