.node-container[data-v-e128b0e2] {
  display: flex;
  border: 2px solid #187dff;
  border-radius: 50%;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
