.button[data-v-026fb6fd] {
  text-align: end;
}
.title[data-v-026fb6fd] {
  text-align: left;
  margin-bottom: 16px;
}
.instruction-container[data-v-026fb6fd] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.instruction-container .editor-container[data-v-026fb6fd] {
  flex: 1;
  overflow: hidden;
}
